import { Divider, Form, Input, Modal, Row, Select, Space } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {} from '../../../redux/hwc/hwcProductSlice';
import { Country, COUNTRY_NAMES } from '../../../shared/utils/constants';
import { HwcFulfillOrder } from '../../../custom_types/hwc/hwc.fulfillorder';
import {
  selectHwcFulfillOrdersLoading,
  selectHwcShowModifyOrderModal,
  setshowModifyOrderModal
} from '../../../redux/hwc/hwcFulfillOrderSlice';

interface HwcFulfillOrderModifyModalProps {
  record: HwcFulfillOrder;
  onOkClick: (record: HwcFulfillOrder) => void;
}

const HwcFulfillOrderModifyModal = ({ record, onOkClick }: HwcFulfillOrderModifyModalProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModifyFillOrderModal = useSelector(selectHwcShowModifyOrderModal);
  const loading = useSelector(selectHwcFulfillOrdersLoading);

  form.setFieldsValue({
    contact: record.contact,
    mobile: record.mobile,
    address: record.address,
    country: record.country,
    city: record.city,
    state: record.state,
    zip: record.zip
  });

  const cancelHandler = () => {
    form.resetFields();
    dispatch(setshowModifyOrderModal(false));
  };

  const modifyOrderHandler = () => {
    form.validateFields().then((values) => {
      console.log(values);
      const newOrder: HwcFulfillOrder = {
        ...record,
        ...values
      };
      console.log(newOrder);
      onOkClick(newOrder);
    });
  };

  return (
    <Modal
      closable={false}
      title={<div>修改订单</div>}
      cancelText="关闭"
      onCancel={cancelHandler}
      open={showModifyFillOrderModal}
      okText="重新下单"
      onOk={modifyOrderHandler}
      okButtonProps={{ loading }}
      width={1500}
    >
      <Form form={form}>
        <Divider orientation="left">收件人信息</Divider>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件人名"
              name="contact"
              rules={[{ required: true, message: '请输入收件人名' }]}
            >
              <Input placeholder="收件人名" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件电话"
              name="mobile"
              rules={[{ required: true, message: '请输入收件电话' }]}
            >
              <Input placeholder="收件电话" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件地址"
              name="address"
              rules={[{ required: true, message: '请输入收件地址' }]}
            >
              <Input placeholder="收件地址" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="收件国家"
              name="country"
              initialValue={Country.USA}
              rules={[{ required: true, message: '请输入收件国家' }]}
            >
              <Select
                style={{ width: 290 }}
                placeholder="请选择收件国家"
                options={[{ label: COUNTRY_NAMES[Country.USA], value: Country.USA }]}
              />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件城市"
              name="city"
              rules={[{ required: true, message: '请输入收件城市' }]}
            >
              <Input placeholder="收件城市" style={{ width: 290 }} />
            </Form.Item>
            <Form.Item
              label="州(代码)"
              name="state"
              rules={[{ required: true, message: '请输入州(代码)' }]}
            >
              <Input placeholder="州(代码)" style={{ width: 290 }} maxLength={2} />
            </Form.Item>
          </Space>
        </Row>
        <Row>
          <Space size="large">
            <Form.Item
              label="收件邮编"
              name="zip"
              rules={[{ required: true, message: '请输入收件邮编' }]}
            >
              <Input placeholder="收件邮编" style={{ width: 290 }} />
            </Form.Item>
          </Space>
        </Row>
        {/* <Divider orientation="left">商品信息</Divider>
        <Button
          type="primary"
          onClick={addFulfillStockHandler}
          icon={<PlusOutlined />}
          disabled={ckCode === undefined}
        >
          添加明细
        </Button>
        {orderProducts.length > 0 ? (
          <Table<HwcFulFillProduct>
            rowKey={(record) => record.sku}
            columns={[
              ...columns,
              {
                title: '操作',
                key: 'operations',
                render: (_, __, index: number) => (
                  <Button type="link" onClick={() => removeProductItem(index)}>
                    删除
                  </Button>
                )
              }
            ]}
            dataSource={orderProducts}
          />
        ) : (
          <Empty
            style={{ width: '90%', height: '90%' }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={<Typography.Text>还没添加商品，点击添加明细按钮添加商品</Typography.Text>}
          >
            <Button type="primary" onClick={addFulfillStockHandler} disabled={ckCode === undefined}>
              现在添加
            </Button>
          </Empty>
        )} */}
      </Form>
    </Modal>
  );
};

export default HwcFulfillOrderModifyModal;
