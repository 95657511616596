import React, { ReactElement, useEffect } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-layout';
import { SyncOutlined } from '@ant-design/icons';
import { HwcBill } from '../../../custom_types/hwc/hwc.bill';
import {
  fetchHwcBillsHandler,
  selectHwcBills,
  selectHwcBillsLoading
} from '../../../redux/hwc/hwcBillSlice';

const HwcClientBillingPanel = (): ReactElement => {
  const dispatch = useDispatch();
  const bills = useSelector(selectHwcBills);
  const loading = useSelector(selectHwcBillsLoading);

  useEffect(() => {
    dispatch(fetchHwcBillsHandler());
  }, [dispatch]);

  const columns = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number): ReactElement => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: '账单编码',
      key: 'code',
      dataIndex: 'code'
    },
    { title: '账单类型', key: 'type', dataIndex: 'type' },
    { title: '订单号', key: 'orderId', dataIndex: 'orderId' },
    { title: '仓库编码', key: 'ck_num', dataIndex: 'ck_num' },
    {
      title: '结算日期',
      key: 'date',
      dataIndex: 'date',
      render: (date: string): ReactElement => {
        return (
          <div className="transation_table_cell">
            <div>
              <FormattedDate value={new Date(date)} year="numeric" month="2-digit" day="2-digit" />
            </div>
            <div>
              <FormattedTime value={new Date(date)} />
            </div>
          </div>
        );
      }
    },
    {
      title: '操作费',
      key: 'operationFee',
      dataIndex: 'operationFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return (
          <>
            {record.operationFee.length === 0
              ? 0
              : record.operationFee.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2)}
          </>
        );
      }
    },
    {
      title: '物流费',
      key: 'shippingFee',
      dataIndex: 'shippingFee',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.shippingFee.toFixed(2)}</>;
      }
    },
    // {
    //   title: '仓储费',
    //   key: 'storageFee',
    //   dataIndex: 'storageFee'
    // },
    // {
    //   title: '其他费用',
    //   key: 'otherFee',
    //   dataIndex: 'otherFee'
    // },
    {
      title: '押金',
      key: 'deposit',
      dataIndex: 'deposit',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.deposit.toFixed(2)}</>;
      }
    },
    {
      title: '可用余额',
      key: 'balance',
      dataIndex: 'balance',
      render: (_: any, record: HwcBill): ReactElement => {
        return <>{record.balance.toFixed(2)}</>;
      }
    },
    {
      title: '备注',
      key: 'remark',
      dataIndex: 'remark'
    }
  ];

  return (
    <>
      <PageHeader
        title=""
        extra={[
          <Button
            type="primary"
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcBillsHandler())}
          >
            同步账单
          </Button>
        ]}
      />
      <Table<HwcBill>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={bills}
        loading={loading}
      />
    </>
  );
};

export default HwcClientBillingPanel;
