import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tabs,
  TableProps,
  TableColumnsType
} from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useForm } from 'antd/es/form/Form';
import {
  deleteFulfillOrdersHandler,
  reCreateHwcFulfillOrderHandler,
  revertFulfillOrdersHandler,
  searchHwcClientFulfillOrdersHandler,
  selectHwcFulfillOrders,
  selectHwcFulfillOrdersLoading,
  selectHwcShowModifyOrderModal,
  setshowModifyOrderModal
} from '../../../redux/hwc/hwcFulfillOrderSlice';
import { HwcClientWarehouse } from '../../../custom_types/redux-types';
import {
  fetchHwcClientWarehousesHandler,
  selectHwcClientWarehouses
} from '../../../redux/hwc/hwcProductSlice';
import {
  ClientFulfillOrderSearchQuery,
  HwcFulfillOrder
} from '../../../custom_types/hwc/hwc.fulfillorder';
import { HwcFulfillOrderDelStatus } from '../../../shared/utils/constants';
import HwcFulfillOrderModifyModal from '../components/HwcFulfillOrderModifyModal';

const HwcFulfillOrderPage = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const hwcFulfillOrders = useSelector(selectHwcFulfillOrders);
  const loading = useSelector(selectHwcFulfillOrdersLoading);
  const showModal = useSelector(selectHwcShowModifyOrderModal);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [
    selectHwcClientWarehouse,
    setSelectHwcClientWarehouse
  ] = React.useState<HwcClientWarehouse>(hwcClientWarehouseList[0]);
  const [delStatus, setDelStatus] = useState(HwcFulfillOrderDelStatus.UNDELETED);
  const [startDate, setStartDate] = React.useState<string>(
    dayjs().subtract(1, 'week').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState<string>(dayjs().format('YYYY-MM-DD'));
  const [selectedFulfillOrders, setSelectedFulfillOrders] = useState<HwcFulfillOrder[]>([]);
  const [curFulfillOrder, setCurFulfillOrder] = useState<HwcFulfillOrder>();

  useEffect(() => {
    if (!hwcClientWarehouseList || hwcClientWarehouseList.length === 0) {
      dispatch(fetchHwcClientWarehousesHandler());
    }
    if (hwcClientWarehouseList && hwcClientWarehouseList.length > 0) {
      setSelectHwcClientWarehouse(hwcClientWarehouseList[0]);
      form.validateFields().then((values: any) => {
        const searchValues: ClientFulfillOrderSearchQuery = {
          ...values,
          startDate,
          endDate,
          deleteStatus: delStatus,
          hwcClientWarehouseId: hwcClientWarehouseList[0].id
        };
        dispatch(searchHwcClientFulfillOrdersHandler(searchValues));
      });
    }
  }, [dispatch, hwcClientWarehouseList, form, startDate, endDate, delStatus]);

  const handleHwcClientWarehouseChange = (id: string) => {
    const clientWarehouse = hwcClientWarehouseList.find((item) => item.id === id);
    if (clientWarehouse) {
      setSelectHwcClientWarehouse(clientWarehouse);

      form.validateFields().then((values: any) => {
        const searchValues: ClientFulfillOrderSearchQuery = {
          ...values,
          startDate,
          endDate,
          deleteStatus: delStatus,
          hwcClientWarehouseId: clientWarehouse.id
        };
        dispatch(searchHwcClientFulfillOrdersHandler(searchValues));
      });
    }
  };

  const searchRecords = async () => {
    form.validateFields().then((values: any) => {
      const searchValues: ClientFulfillOrderSearchQuery = {
        ...values,
        startDate,
        endDate,
        deleteStatus: delStatus,
        hwcClientWarehouseId: selectHwcClientWarehouse
          ? selectHwcClientWarehouse.id
          : hwcClientWarehouseList[0].id
      };
      console.log('search values:', searchValues);
      dispatch(searchHwcClientFulfillOrdersHandler(searchValues));
    });
  };

  const resetRecords = async () => {
    form.resetFields();
    setStartDate(dayjs().subtract(1, 'week').format('YYYY-MM-DD'));
    setEndDate(dayjs().format('YYYY-MM-DD'));
    setDelStatus(HwcFulfillOrderDelStatus.UNDELETED);
    dispatch(
      searchHwcClientFulfillOrdersHandler({
        startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        deleteStatus: HwcFulfillOrderDelStatus.UNDELETED,
        hwcClientWarehouseId: selectHwcClientWarehouse
          ? selectHwcClientWarehouse.id
          : hwcClientWarehouseList[0].id
      })
    );
  };

  const tabChangeHandler = (key: string) => {
    setDelStatus(key as HwcFulfillOrderDelStatus);
  };

  const handleFulfillOrderDelete = (orders: HwcFulfillOrder[]) => {
    form.validateFields().then((values: any) => {
      const searchValues: ClientFulfillOrderSearchQuery = {
        ...values,
        startDate,
        endDate,
        deleteStatus: delStatus,
        hwcClientWarehouseId: selectHwcClientWarehouse
          ? selectHwcClientWarehouse.id
          : hwcClientWarehouseList[0].id
      };
      // console.log(orders);
      dispatch(
        deleteFulfillOrdersHandler(
          selectHwcClientWarehouse!.id,
          orders.map((o) => o.deliver_no),
          searchValues
        )
      );
    });
  };

  const handleFulfillOrderRevert = (orders: HwcFulfillOrder[]) => {
    form.validateFields().then((values: any) => {
      const searchValues: ClientFulfillOrderSearchQuery = {
        ...values,
        startDate,
        endDate,
        deleteStatus: delStatus,
        hwcClientWarehouseId: selectHwcClientWarehouse
          ? selectHwcClientWarehouse.id
          : hwcClientWarehouseList[0].id
      };
      // console.log(orders);
      dispatch(
        revertFulfillOrdersHandler(
          selectHwcClientWarehouse!.id,
          orders.map((o) => o.deliver_no),
          searchValues
        )
      );
    });
  };

  const handleModigyClicked = (record: HwcFulfillOrder) => {
    setCurFulfillOrder(record);
    dispatch(setshowModifyOrderModal(true));
  };

  const recreateOrderhandler = (order: HwcFulfillOrder) => {
    form.validateFields().then((values: any) => {
      const searchValues: ClientFulfillOrderSearchQuery = {
        ...values,
        startDate,
        endDate,
        deleteStatus: delStatus,
        hwcClientWarehouseId: selectHwcClientWarehouse
          ? selectHwcClientWarehouse.id
          : hwcClientWarehouseList[0].id
      };
      console.log(order);
      dispatch(
        reCreateHwcFulfillOrderHandler(
          order,
          selectHwcClientWarehouse ? selectHwcClientWarehouse.id : hwcClientWarehouseList[0].id,
          searchValues
        )
      );
    });
  };

  // rowSelection object indicates the need for row selection
  const rowSelection: TableProps<HwcFulfillOrder>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: HwcFulfillOrder[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedFulfillOrders([...selectedRows]);
    }
  };

  const columns: TableColumnsType<HwcFulfillOrder> = [
    {
      title: '序号',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: '订单日期',
      key: 'addtime',
      dataIndex: 'addtime',
      render: (addtime: Date): ReactElement => {
        return (
          <>
            <div>{dayjs(addtime).format('YYYY/MM/DD')}</div>
            <div>{dayjs(addtime).format('HH:mm:ss')}</div>
          </>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a: HwcFulfillOrder, b: HwcFulfillOrder) => {
        return dayjs(a.addtime!).toDate().getTime() - dayjs(b.addtime!).toDate().getTime();
      }
    },
    {
      title: '订单号',
      key: 'deliver_no',
      dataIndex: 'deliver_no',
      sorter: (a: HwcFulfillOrder, b: HwcFulfillOrder) => a.deliver_no.localeCompare(b.deliver_no)
    },
    {
      title: '平台单号',
      key: 'erpOrderId',
      dataIndex: 'erpOrderId'
    },
    {
      title: '仓库编码',
      key: 'ck_nums',
      dataIndex: 'ck_nums'
    },
    {
      title: '物流渠道',
      key: 'chqd',
      dataIndex: 'chqd'
    },
    {
      title: '收件人',
      key: 'recepient',
      dataIndex: 'recepient',
      render: (_: any, record: HwcFulfillOrder): ReactElement => {
        return (
          <>
            <div>
              <strong>{record.contact}</strong>
            </div>
            <div>{record.mobile}</div>
            {record.email && <div>{record.email}</div>}
            <div>{record.address}</div>
            <div>
              {record.city} {record.state} {record.zip} {record.country}
            </div>
          </>
        );
      }
    },
    {
      title: '商品信息',
      key: 'products',
      dataIndex: 'products',
      render: (_: any, record: HwcFulfillOrder): ReactElement => {
        return (
          <>
            {record.deliver_products.map((product) => (
              <div key={product.sku}>{`${product.sku} x ${product.nums}`}</div>
            ))}
          </>
        );
      }
    },
    {
      title: '物流单号',
      key: 'waybill',
      dataIndex: 'waybill'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (status: string): ReactElement => {
        return (
          <>
            {status === '1' && <div>待确认</div>}
            {status === '2' && <div>待发货</div>}
            {status === '3' && <div>已打包</div>}
            {status === '4' && <div>已发货</div>}
            {status === '5' && <div>审核不通过</div>}
            {status === '6' && <div>已签收</div>}
            {status === '0' && <div>草稿箱</div>}
            {status === '9' && <div>错误</div>}
          </>
        );
      }
    },
    {
      title: '信息',
      key: 'remark',
      dataIndex: 'remark'
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: HwcFulfillOrder): ReactElement => {
        return (
          <Space direction="vertical">
            {(record.status === '1' || record.status === '2') &&
              delStatus === HwcFulfillOrderDelStatus.UNDELETED && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleFulfillOrderDelete([record])}
                >
                  取消订单
                </Button>
              )}
            {record.pda_url && delStatus === HwcFulfillOrderDelStatus.UNDELETED && (
              <Button type="primary" size="small" href={record.pda_url} target="_blank">
                打印面单
              </Button>
            )}
            {delStatus === HwcFulfillOrderDelStatus.WAITING && (
              <Button
                type="primary"
                size="small"
                onClick={() => handleFulfillOrderRevert([record])}
              >
                复原订单
              </Button>
            )}
            {record.status === '9' && (
              <Button type="primary" size="small" onClick={() => handleModigyClicked(record)}>
                修改订单
              </Button>
            )}
          </Space>
        );
      }
    }
  ];

  return (
    <div>
      {showModal && curFulfillOrder && (
        <HwcFulfillOrderModifyModal record={curFulfillOrder} onOkClick={recreateOrderhandler} />
      )}
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Select
              defaultValue={selectHwcClientWarehouse.code}
              style={{ width: 200 }}
              options={hwcClientWarehouseList.map((item) => ({
                label: item.name,
                value: item.code
              }))}
              onChange={handleHwcClientWarehouseChange}
            />
          )
        }
        extra={[
          <Button key="create" type="primary" icon={<SearchOutlined />} onClick={searchRecords}>
            搜索订单
          </Button>,
          <Button
            key="1"
            type="default"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={resetRecords}
          >
            重置搜索
          </Button>
          // <Button
          //   key="1"
          //   type="primary"
          //   disabled={!selectHwcClientWarehouse}
          //   icon={<SyncOutlined />}
          //   loading={loading}
          //   onClick={() => dispatch(fetchHwcFulfillOrdersHandler(selectHwcClientWarehouse.code))}
          // >
          //   同步订单
          // </Button>
        ]}
      >
        <Form form={form} layout="horizontal">
          <Space direction="horizontal" size="middle">
            <Form.Item label="开始日期" name="startDate">
              <DatePicker
                defaultValue={dayjs(startDate, 'YYYY-MM-DD')}
                format="YYYY-MM-DD"
                onChange={(_, dateString) => {
                  // console.log('start date:', dateString);
                  setStartDate(dateString as string);
                }}
              />
            </Form.Item>
            <Form.Item label="结束日期" name="endDate">
              <DatePicker
                defaultValue={dayjs(endDate, 'YYYY-MM-DD')}
                format="YYYY-MM-DD"
                onChange={(_, dateString) => {
                  // console.log('start date:', dateString);
                  setEndDate(dateString as string);
                }}
              />
            </Form.Item>
            <Form.Item label="订单号" name="deliver_no">
              <Input type="text" placeholder="订单号" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="平台订单号" name="erpOrderId">
              <Input type="text" placeholder="平台订单号" style={{ width: 300 }} />
            </Form.Item>
          </Space>
          <Space direction="horizontal" size="middle">
            <Form.Item label="面单号" name="waybill">
              <Input type="text" placeholder="面单号" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="收件人" name="contact">
              <Input type="text" placeholder="收件人" />
            </Form.Item>
            <Form.Item label="邮编" name="zip">
              <Input type="text" placeholder="邮编" />
            </Form.Item>
            <Form.Item label="状态" name="status" initialValue="">
              <Select style={{ width: 150 }}>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value="1">待确认</Select.Option>
                <Select.Option value="2">待发货</Select.Option>
                <Select.Option value="4">已发货</Select.Option>
                <Select.Option value="9">错误</Select.Option>
              </Select>
            </Form.Item>
          </Space>
        </Form>
      </PageHeader>
      <Tabs defaultActiveKey="0" onChange={tabChangeHandler}>
        <Tabs.TabPane tab="正常" key={HwcFulfillOrderDelStatus.UNDELETED} />
        <Tabs.TabPane tab="待取消" key={HwcFulfillOrderDelStatus.WAITING} />
        <Tabs.TabPane tab="已取消" key={HwcFulfillOrderDelStatus.DELETED} />
      </Tabs>
      {delStatus === HwcFulfillOrderDelStatus.UNDELETED && (
        <Button
          type="primary"
          onClick={() => {
            handleFulfillOrderDelete(selectedFulfillOrders);
            setSelectedFulfillOrders([]);
          }}
          disabled={selectedFulfillOrders.length === 0}
        >
          批量取消订单
        </Button>
      )}
      {delStatus === HwcFulfillOrderDelStatus.WAITING && (
        <Button
          type="primary"
          onClick={() => {
            handleFulfillOrderRevert(selectedFulfillOrders);
            setSelectedFulfillOrders([]);
          }}
          disabled={selectedFulfillOrders.length === 0}
        >
          批量复原订单
        </Button>
      )}
      <Table<HwcFulfillOrder>
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={hwcFulfillOrders}
        loading={loading}
      />
    </div>
  );
};

export default HwcFulfillOrderPage;
